import React from 'react'
import { Link } from 'gatsby'

const ImageGallery = ({ slice }) => (
  <section className="image-gallery content-section">
    <div>{slice.primary.gallery_title.text}</div>
    <div className="gallery">
      {slice.items.map((galleryItem, index) => (
        <div className="gallery-item" key={`gallery-item=${index}`}>
          <img
            src={galleryItem.image.url}
            alt={galleryItem.image.alt}
          />
          <div>{galleryItem.image_description.text}</div>
          {galleryItem.link && galleryItem.link.uid
            ? (
              <p className="gallery-link">
                <Link to={galleryItem.link.uid}>
                  <p>{galleryItem.link_label.text}</p>
                </Link>
              </p>
            )
            : null}
        </div>
      ))}
    </div>
  </section>
)

export default ImageGallery