import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

import Container from '../layout/Container'
import LinkAsButton from '../layout/LinkAsButton'

const Row = styled.div`
  grid-column: 1 / span 6; 
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12; 
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 2 / span 12; 
  }
`

const Text = styled.div`
  margin: 2rem 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 100%;

  @media ${(props) => props.theme.breakpoints.m} {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    padding-right: 48px;
    padding-left: 48px;
  }

  p {
    margin: 24px 0;
  }

  ul {
    color: ${(props) => props.theme.color.light};
    font-size: 18px;
    font-weight: 300;
    list-style: none;
  }
  
  ul > li {
    text-indent: 0;
  }
  
  ul > li:before {
    content: "-";
    margin-right: 12px;
  }

  h1 {
    background: #FFDE0A;
    color: rgba(0,0,0,0.87);
    display: inline;
    font-family: Roboto Slab, serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.7;
    padding: 0.125rem 0;
    -webkit-box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
    box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
    -webkit-box-decoration-break:clone;
    -moz-box-decoration-break:clone; 
    box-decoration-break: clone;
  }
`

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.s} {
    margin: 2rem 0;
  }
`

const Image = styled.div`
  position: relative;

  ::after {
    display: block;
    content '';
    padding-bottom: 66.67%
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Caption = styled.caption`
  color: ${(props) => props.theme.color.light};
  font-size: 14px;
  font-style: italic;
  text-align: left;
  margin-top: 4px;
  width: 100%;
`

const ImageHighlight = ({ slice }) => {

  const position = slice.primary.position === 'Afbeelding links'
    ? 'image-on-the-left-side'
    : 'image-on-the-right-side'

  console.log(slice.primary.position)



  return (
    <Container>
      <Row className={`${position}`}>
        <Figure>
          <Image>
            <img
              src={slice.primary.featured_image.url}
              alt={slice.primary.featured_image.alt}
            />
          </Image>
          <Caption>{slice.primary.image_caption.text}</Caption>
        </Figure>
        <Text>
          <RichText 
            render={slice.primary.title.raw}
          />
          <RichText
            render={slice.primary.description.raw}
          />
          {slice.primary.link_label.text
          ? <LinkAsButton href={`${slice.primary.link.url}`}>{slice.primary.link_label.text}</LinkAsButton>
          : <span />
          }
        </Text>
        
      </Row>
    </Container>
  )
}

export default ImageHighlight