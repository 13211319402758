import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

const Table = ({ slice }) => {

  const Table = styled.table`
    font-weight: 300;
    display: block;
    padding: 1rem 0;
  `

  const TableRow = styled.tr`
  `

  const TableData = styled.td`
    padding: 0.25rem 1rem 0.25rem 0;

    p {
      margin: 0;
    }
  `

  const TableContent = slice.items

  return (
    <Table>
      <tbody>
        {TableContent.map((row, index) => (
          <TableRow key={index}>
            <TableData>
              <RichText
                render={row.column_1.raw}
              />
            </TableData>
            <TableData>
              <RichText
                render={row.column_2.raw}
              />
            </TableData>
          </TableRow>
        ))}
      </tbody>
    </Table>
  )

}

export default Table