 import styled from 'styled-components'

 const LinkAsButton = styled.a`
  background-color: ${(props) => props.theme.color.secondary};
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  transition: all 0.3s ease;
  height: 36px;

  :hover {
    background-color: ${(props) => props.theme.color.secondary_lighter};
    transition: all 0.3s ease;
  }
 `

 export default LinkAsButton