import React from 'react'
import FullWidthImage from './slices/FullWidthImage'
import ImageGallery from './slices/ImageGallery'
import ImageHighlight from './slices/ImageHighlight'
import Quote from './slices/Quote'
import Table from './slices/Table'
import Text from './slices/Text'
import TextList from './slices/TextList'
import TextMultiColumn from './slices/TextMultiColumn'


const SliceZone = ({ sliceZone }) => {

    const sliceComponents = {
        full_width_image: FullWidthImage,
        image_gallery: ImageGallery,
        image_highlight: ImageHighlight,
        quote: Quote,
        text: Text,
        "text_-_list": TextList,
        "text_-_multi_column": TextMultiColumn,
        table: Table,
    }

    if(sliceZone) {

        const sliceZoneContent = sliceZone.map((slice, index) => {
            const SliceComponent = sliceComponents[slice.slice_type]

            if (SliceComponent) {
                return <SliceComponent slice={slice} key={`slice-${index}`} />
            }
            return 'no slice components found...'
        })

        return (sliceZoneContent)
    }

    return (null);

}

export default SliceZone