import { RichText } from 'prismic-reactjs'
import React from 'react'
import styled from 'styled-components'

import Section from '../layout/Section'
import Container from '../layout/Container'

const Text = styled.div`
  grid-column: 1 / span 6;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 10;
  }
`

const Figure = styled.figure`
  grid-column: 1 / span 6;
  background-color: #eee;
  color: ${(props) => props.theme.color.light};
  font-weight: 300;
  font-style: italic;
  margin: 32px 0;
  padding: 24px 48px;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12;
    margin: 4rem 4rem;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 10;
  }
`

const BlockQuote = styled.blockquote`
  position: relative;

  :before {
    color: rgba(0,0,0,0.12);
    content: "“";
    font-family: Roboto Slab;
    font-size: 96px;
    position: absolute;
    left: -48px;
    top: -48px;
  }
`

const Cite = styled.cite`
  display: inline-block;  
  font-style: normal;
  font-weight: 400;
  padding-top: 8px;

  :before {
    content: "-";
    margin-right: 12px;
  }
`

const Quote = ({ slice }) => (
  <Section>
    <Container>
      <Text>
        <RichText
          render={slice.primary.heading.raw}
        />
      </Text>
      <Figure>
        <BlockQuote>{slice.primary.quote.text}</BlockQuote>
        <Cite>{slice.primary.cite.text}</Cite>
      </Figure>
    </Container>
  </Section>
)

export default Quote