import { RichText } from 'prismic-reactjs'
import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.section`
  grid-column: 2 / span 4;

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 2 / span 12;
    padding: 3rem;
  }

  ul {
    color: ${(props) => props.theme.color.light};
    font-size: 18px;
    font-weight: 300;
    list-style: none;
  }
  
  ul > li {
    text-indent: 0;
  }
  
  ul > li:before {
    content: "-";
    margin-right: 12px;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  width: 100%;
`

const Column = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 1;
  width: 100%;
  max-width: 100%;
`

const TextMultiColumn = ({ slice }) => {

  console.log(slice.primary.heading.raw)

  return (
    <StyledSection>
      <RichText 
        render={slice.primary.heading.raw}
      />
      <Row>
        {slice.items.map(( contentItem, index) => (
          <Column key={index}>
            <RichText 
              render={contentItem.content.raw}
            />            
          </Column>
        ))}
      </Row>
    </StyledSection>
  )
}

export default TextMultiColumn