import { RichText } from 'prismic-reactjs'
import React from 'react'
import styled from 'styled-components'

import Section from '../layout/Section'
import Container from '../layout/Container'

const StyledSection = styled(Section)`

  ul {
    color: ${(props) => props.theme.color.light};
    font-size: 18px;
    font-weight: 300;
    list-style: none;
  }
  
  ul > li {
    text-indent: 0;
  }
  
  ul > li:before {
    content: "-";
    margin-right: 12px;
  }
`

const Text = styled.div`
  grid-column: 1 / span 6;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 10;
  }
`

const Intro = styled.div`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.xs} {
    width: 70%;
  }
`

const Row = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 12;
  }

  @media ${(props) => props.theme.breakpoints.l} {
    grid-column: 3 / span 10;
  }
`

const Col = styled.div`
  flex: 0 0 100%;
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
  min-height: 1px;
  max-width: 100%;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.m} {
    flex: 0 0 50%;
    max-width: 50%;
  }
`

const TextList = ({ slice }) => {

  return (
    <StyledSection>
      <Container>
        <Text>
          <RichText 
            render={slice.primary.heading.raw}
          />
          <Intro>
            <RichText 
              render={slice.primary.intro.raw}
            />
          </Intro>
        </Text>
        <Row>
          {slice.items.map(( listItem, index) => (
            <Col key={index}>
              <RichText 
                render={listItem.list_item.raw}
              />
            </Col>
          ))}
        </Row>  
      </Container>
    </StyledSection>
  )
}

export default TextList